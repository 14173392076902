// Logos
import LogoCristal from '../assets/produtos/cachaca_sp/cristal/logo.png';
import LogoMelLimao from '../assets/produtos/cachaca_sp/mel_limao/logo.png';
import LogoCaipira from '../assets/produtos/cachaca_caipira/logo.png';
import LogoCigana from '../assets/produtos/cachaca_cigana/logo.png';

// Just garrafa
import SaoPauloCristal from "../assets/produtos/cachaca-logo/sao_paulo_cristal.png";
import SaoPauloMelLimao from "../assets/produtos/cachaca-logo/sao_paulo_mel_e_limao.png";
import SaoPauloOriginal from "../assets/produtos/cachaca-logo/sao_paulo_original.png";

import ImageAbacaxi1 from '../assets/produtos/cachaca_sp/abacaxi_coco/1.jpg';
import ImageAbacaxi3 from '../assets/produtos/cachaca_sp/abacaxi_coco/3.jpg';
import ImageCristal1 from '../assets/produtos/cachaca_sp/cristal/1.jpg';
import ImageCristal2 from '../assets/produtos/cachaca_sp/cristal/2.jpg';
import ImageCristal3 from '../assets/produtos/cachaca_sp/cristal/3.jpg';
import ImageMelLimao1 from '../assets/produtos/cachaca_sp/mel_limao/1.jpg';
import ImageMelLimao2 from '../assets/produtos/cachaca_sp/mel_limao/2.jpg';
import ImageMelLimao3 from '../assets/produtos/cachaca_sp/mel_limao/3.jpg';
import ImageOriginal1 from '../assets/produtos/cachaca_sp/original/1.jpg';
import ImageOriginal2 from '../assets/produtos/cachaca_sp/original/2.jpg';
import ImageOriginal3 from '../assets/produtos/cachaca_sp/original/3.jpg';
import ImageCarvalho1 from '../assets/produtos/cachaca_sp/carvalho/1.jpg';
import ImageCarvalho2 from '../assets/produtos/cachaca_sp/carvalho/2.jpg';
import ImageCarvalho3 from '../assets/produtos/cachaca_sp/carvalho/3.jpg';
import ImageAmburana1 from '../assets/produtos/cachaca_sp/amburana/1.jpg';
import ImageAmburana2 from '../assets/produtos/cachaca_sp/amburana/2.jpg';
import ImageAmburana3 from '../assets/produtos/cachaca_sp/amburana/3.jpg';
import ImageCaipiraTrad1 from '../assets/produtos/cachaca_caipira/tradicional/1.jpg';
import ImageCaipiraTrad2 from '../assets/produtos/cachaca_caipira/tradicional/2.jpg';
import ImageCaipiraTrad3 from '../assets/produtos/cachaca_caipira/tradicional/3.jpg';
import ImageCaipiraAmb1 from '../assets/produtos/cachaca_caipira/amburana/1.jpg';
import ImageCaipiraAmb2 from '../assets/produtos/cachaca_caipira/amburana/2.jpg';
import ImageCaipiraAmb3 from '../assets/produtos/cachaca_caipira/amburana/3.jpg';
import ImageCiganaCar1 from '../assets/produtos/cachaca_cigana/carvalho/1.jpg';
import ImageCiganaCar2 from '../assets/produtos/cachaca_cigana/carvalho/2.jpg';
import ImageCiganaCar3 from '../assets/produtos/cachaca_cigana/carvalho/3.jpg';
import ImageCiganaBi1 from '../assets/produtos/cachaca_cigana/bidestilada/0.jpg';
import ImageCiganaBi2 from '../assets/produtos/cachaca_cigana/bidestilada/1.jpg';
import ImageCiganaBi3 from '../assets/produtos/cachaca_cigana/bidestilada/3.jpg';
import ImageUs1 from '../assets/produtos/us/1.jpg';
import ImageUs2 from '../assets/produtos/us/2.jpg';

export const DRINKS_LIST = [
    {
        id: 0,
        name: 'São Paulo Abacaxi e Coco',
        isUs: false,
        logo1l: SaoPauloCristal,
        logo: LogoCristal,
        year: 'common.1909',
        image1: ImageAbacaxi1,
        description1: 'our_drinks.sp_abacaxi_coco_description_1',
        image2: '',
        image3: ImageAbacaxi3,
        description3: 'our_drinks.sp_abacaxi_coco_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/sp_abacaxi_coco/abacaxi_com_coco.pdf',
        percent: '20% - 355ml / 1L'
    },
    {
        id: 1,
        name: 'São Paulo Cristal',
        isUs: false,
        logo1l: SaoPauloCristal,
        logo: LogoCristal,
        year: 'common.1909',
        image1: ImageCristal1,
        description1: 'our_drinks.sp_cristal_description_1',
        image2: ImageCristal2,
        image3: ImageCristal3,
        description3: 'our_drinks.sp_cristal_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/sp_cristal/sp_cristal.pdf',
        percent: '39% - 355ml / 1L'
    },
    {
        id: 2,
        name: 'São Paulo Mel e Limão',
        isUs: false,
        logo1l: SaoPauloMelLimao,
        logo: LogoMelLimao,
        year: 'common.1909',
        image1: ImageMelLimao1,
        description1: 'our_drinks.sp_mel_limao_description_1',
        image2: ImageMelLimao2,
        image3: ImageMelLimao3,
        description3: 'our_drinks.sp_mel_limao_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/sp_mel_limao/sp_mel_limao.pdf',
        percent: '20% - 355ml / 1L'
    },
    {
        id: 3,
        name: 'São Paulo Original',
        isUs: false,
        logo1l: SaoPauloOriginal,
        logo: LogoCristal,
        year: 'common.1909',
        image1: ImageOriginal1,
        description1: 'our_drinks.sp_original_description_1',
        image2: ImageOriginal2,
        image3: ImageOriginal3,
        description3: 'our_drinks.sp_original_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/sp_original/sp_original.pdf',
        percent: '42% - 1L'
    },
    {
        id: 4,
        name: 'São Paulo Carvalho',
        isUs: false,
        logo: LogoCristal,
        year: 'common.1909',
        image1: ImageCarvalho1,
        description1: 'our_drinks.sp_carvalho_description_1',
        image2: ImageCarvalho2,
        image3: ImageCarvalho3,
        description3: 'our_drinks.sp_carvalho_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/sp_carvalho/sp_carvalho.pdf',
        percent: '40% - 355ml / 1L'
    },
    {
        id: 5,
        name: 'São Paulo Amburana',
        isUs: false,
        logo: LogoCristal,
        year: 'common.1909',
        image1: ImageAmburana1,
        description1: 'our_drinks.sp_amburana_description_1',
        image2: ImageAmburana2,
        image3: ImageAmburana3,
        description3: 'our_drinks.sp_amburana_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/sp_amburana/sp_amburana.pdf',
        percent: '41% - 355ml / 1L'
    },
    {
        id: 6,
        name: 'São Paulo Cristal USA Export',
        isUs: true,
        logo: LogoCristal,
        year: 'common.1909',
        image1: ImageUs1,
        description1: 'our_drinks.sp_award_description_1',
        image2: ImageCristal2,
        image3: ImageCristal3,
        description3: 'our_drinks.sp_award_description_3',
        catalog_url: '',
        percent: ''
    },
    {
        id: 7,
        name: 'São Paulo Blended',
        isUs: true,
        logo: LogoCristal,
        year: 'common.1909',
        image1: ImageUs2,
        description1: 'our_drinks.sp_blend_description_1',
        image2: ImageAmburana2,
        image3: ImageAmburana2,
        description3: 'our_drinks.sp_blend_description_3',
        catalog_url: '',
        percent: ''
    },
    {
        id: 8,
        name: 'Caipira Tradicional',
        isUs: false,
        logo: LogoCaipira,
        year: 'common.1909',
        image1: ImageCaipiraTrad1,
        description1: 'our_drinks.caip_trad_description_1',
        image2: ImageCaipiraTrad2,
        image3: ImageCaipiraTrad3,
        description3: 'our_drinks.caip_trad_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/caipira_tradicional/caipira_tradicional.pdf',
        percent: '38% - 275ml / 1L'
    },
    {
        id: 9,
        name: 'Caipira Amburana',
        isUs: false,
        logo: LogoCaipira,
        year: 'common.1909',
        image1: ImageCaipiraAmb1,
        description1: 'our_drinks.caip_amburana_description_1',
        image2: ImageCaipiraAmb2,
        image3: ImageCaipiraAmb3,
        description3: 'our_drinks.caip_amburana_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/caipira_amburana/caipira_amburana.pdf',
        percent: '38% - 275ml / 1L'
    },
    {
        id: 10,
        name: 'Cigana Carvalho',
        isUs: false,
        logo: LogoCigana,
        year: 'common.1909',
        image1: ImageCiganaCar1,
        description1: 'our_drinks.cigana_carvalho_description_1',
        image2: ImageCiganaCar2,
        image3: ImageCiganaCar3,
        description3: 'our_drinks.cigana_carvalho_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/cigana_carvalho/cigana_carvalho.pdf',
        percent: '40% - 750ml'
    },
    {
        id: 11,
        name: 'Cigana Bidestilada',
        isUs: false,
        logo: LogoCigana,
        year: 'common.1909',
        image1: ImageCiganaBi1,
        description1: 'our_drinks.cigana_bidestilada_description_1',
        image2: ImageCiganaBi2,
        image3: ImageCiganaBi3,
        description3: 'our_drinks.cigana_bidestilada_description_3',
        catalog_url: 'https://esp-public-files.s3.amazonaws.com/catalog/cigana_bidestilada/cigana_bidestilada.pdf',
        percent: '40% - 750ml'
    }
];