import React, { useEffect } from "react";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { DRINKS_LIST } from '../../constants/DrinksList';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const OurDrinksDetailPage = () => {
    const { t } = useTranslation()
    let { id } = useParams();
    let product = DRINKS_LIST.find(item => item.id === Number(id));

    useEffect(() => {
        document.title = `Engenho São Paulo | ${product.name}`;
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header />

            <div style={{
                backgroundColor: '#E1D3B7'
            }} className="relative flex flex-col py-12 mt-11 w-full max-md:mt-10 max-md:max-w-full">
                <div className="relative self-start mr-[50vw] font-bold text-center text-[130px] sm:text-[180px] text-black text-opacity-10 max-md:mt-10 max-md:max-w-full">
                    {t(`${product.year}`)}
                </div>

                <div style={{
                    position: 'absolute',
                    top: '17vh'
                }}
                    className="font-regular text-xl ml-4"
                >
                    {t('our_drinks.details')}
                </div>
                {product.isUs ? <div
                    style={{
                        backgroundColor: '#85714D',
                        width: '250px', top: '12vh', left: '15px'
                    }}
                    class="absolute grid items-center px-4 py-2 font-sans text-xs font-bold text-white uppercase bg-gray-900 rounded-lg select-none whitespace-nowrap">
                    <span class="">{t('our_drinks.only_usa')}</span>
                </div> : null}

                <div style={{
                    position: 'absolute',
                    top: '20vh'
                }}
                    className="font-bold text-4xl ml-4"
                >
                    {product.name}
                </div>

                <div className=" max-md:mt-10 max-md:max-w-full">
                    <div className="flex max-md:flex-col max-md:gap-0 max-md:">
                        <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
                            <img
                                loading="lazy"
                                src={product.image1}
                                className="w-full "
                            />
                        </div>
                        <div className="flex flex-col items-center justify-center w-[100%] sm:w-[45%]">
                            <div className="flex flex-col items-center justify-center text-base sm:text-base mb-10 sm:mb-0 tracking-widest text-left text-black max-md:max-w-full ">
                                <div >
                                    <img
                                        loading="lazy"
                                        src={product.logo}
                                        className="w-full mt-[5vw] max-w-[20vh] h-auto"
                                    />
                                </div>
                                <div className="container px-[16px] mt-[10vw]">
                                    {t(`${product.description1}`)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {product.image2 && (
                    <div className="w-full"
                        style={{
                            backgroundImage: `url(${product.image2})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '500px'
                        }}>
                    </div>
                )}
                <div className="self-center px-5 max-w-full w-[906px]">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                            <div className="mt-10 mb-10 sm:mb-0 text-3xl font-bold text-center text-black max-md:mt-10 max-md:text-3xl">
                                {t(`${product.description3}`)}
                            </div>
                            {
                                product.percent != '' ?
                                    <div className="mt-10 mb-10 sm:mb-0 text-md font-bold text-center text-black max-md:mt-10 max-md:text-3xl">
                                        {t(`${product.percent}`)}
                                    </div>
                                    :
                                    null
                            }
                            {
                                product.catalog_url != '' ?

                                    <a href={product.catalog_url} target="_blank" rel="noopener noreferrer" className="mt-10 mb-10 sm:mb-0 text-xl font-regular text-center text-black max-md:mt-10 max-md:text-3xl">
                                        <button style={{ borderBottom: '1px solid black' }}>{t('our_drinks.see_catalog')}
                                            <FontAwesomeIcon icon={faArrowRight} size='xs' className="ml-1 text-center" /></button>
                                    </a>
                                    :
                                    null
                            }
                        </div>
                        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full mt-0 sm:mt-2">
                            <img
                                loading="lazy"
                                src={product.image3}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OurDrinksDetailPage;